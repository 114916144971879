import React from 'react'
import { Link } from 'gatsby'

const Services = () => {
  return (
    <div className="position-relative">
      <div className="container-fluid full-width-background-image-container">
        <div className="row h-100 background-beige">
          <div className="col-md-6 order-md-2 background-image background-treatment"></div>
          <div className="col-md-6 order-md-1"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <section className="col-md-6 order-md-2">
            <div className="spacer-background-image"></div>
          </section>

          <section className="col-md-6 order-md-1 p-5">
            <h2>Our Services</h2>
            <p>
              There is a process to getting your hair exactly the way you
              dreamed of it to be.
            </p>
            <p>
              We are experts in providing the best experience for each of our
              clients, whether they are after a classic cut, professional
              straightening, curls, balayage, all-over colour, or styled to
              perfection.
            </p>
            <p>
              We care about the health of your hair, and only use the best
              products on the market.
            </p>
            <p>
              Let's start your tailored hair transformation with Little Hair
              Lounge.
            </p>
            <Link to="/services" className="links">
              What we offer
            </Link>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Services
